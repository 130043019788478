.headshot{
    width: 100%;
    max-height: 300px; 
    width: '100%'; 
    min-width: '60%';
    margin: 0px;
    padding: 0px;
}
.MuiFab-primary{
    width: 400px;
}
.timer-banner{

}
.timer-text{
    margin: 0px;
}

@media screen and (min-width: 900px) {
    .player-name-text {
       font-size: 36px;
    }
  }
.bid-info-text{
    font-weight: normal;
    margin: 0;
}
.player-info-container{
    flex-direction: row;
    display: flex;
    justify-content: center;
}
.noti-text {
    margin: 0;
    -webkit-animation: color_change 0.5s infinite alternate;
    -moz-animation: color_change 0.5s infinite alternate;
    -ms-animation: color_change 0.5s infinite alternate;
    -o-animation: color_change 0.5s infinite alternate;
    animation: color_change 0.5s infinite alternate;
   }
   
   @-webkit-keyframes color_change {
    from { color: black; }
    to { color: green; }
   }
   @-moz-keyframes color_change {
    from { color: black; }
    to { color: green; }
   }
   @-ms-keyframes color_change {
    from { color: black; }
    to { color: green; }
   }
   @-o-keyframes color_change {
    from { color: black; }
    to { color: green; }
   }
   @keyframes color_change {
    from { color: black; }
    to { color: green; }
   }