.chevron-right-active { animation: 1s chevron-right; }
.chevron-left-active { animation: 1s chevron-left; }

@keyframes chevron-right {
    0% { clip-path: polygon(-25% 0%, 0% 50%, -25% 100%, -100% 100%, -75% 50%, -100% 0%); }
    75% { clip-path: polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 25% 50%, 0% 0%); }
    100% { clip-path: polygon(100% 0%, 100% 50%, 100% 100%, 0% 100%, 0% 50%, 0% 0%); }
  }
  @keyframes chevron-leave {
    0% { clip-path: polygon(100% 0%, 100% 50%, 100% 100%, 0% 100%, 0% 50%, 0% 0%); }
    25% { clip-path: polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 25% 50%, 0% 0%); }
    100% { clip-path: polygon(175% 0%, 200% 50%, 175% 100%, 100% 100%, 125% 50%, 100% 0%) }
  }
  @keyframes chevron-left {
    0% { clip-path: polygon(175% 0%, 150% 50%, 175% 100%, 125% 100%, 100% 50%, 125% 0%); }
    75% { clip-path: polygon(75% 0%, 50% 50%, 75% 100%, 25% 100%, 0% 50%, 25% 0%); }
    100% { clip-path: polygon(100% 0%, 100% 50%, 100% 100%, 0% 100%, -75% 50%, 0% 0%); }
  }